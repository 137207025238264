export const supportedLanguages = {
    en: {
        nativeName: 'English',
        shortName: 'en'
    },
    fr: {
        nativeName: 'Français',
        shortName: 'fr'
    },
    es: {
        nativeName: 'Español',
        shortName: 'es'
    }
}